import React from "react";
import Header from "../../Shared/Header";
import BreadCums from "../../Shared/BreadCums";
import Apps from "../../Shared/Apps";
// import privacyHTML from "./privacy.html";

const Privacy = () => {
	return (
		<>
			<Header parentMenu="pages" headerClass="sc-header-section inner-header-style" signUpBtn="inner-btn" />
			<BreadCums pageTitle="Privacy" />
			<div className="m-5">
				<iframe src="./privacy.html" title="Privacy" className="w-100 vh-100" />
			</div>
			<Apps />
		</>
	);
};

export default Privacy;
