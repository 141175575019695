import React from "react";
import { Fade } from "@successtar/react-reveal";
// import { HashLink as Link } from "react-router-hash-link";
// import featureImg3 from "../../../assets/images/feature/feature_img3.png";
import featureImg3BG from "../../../assets/images/feature/feature_img3-BG.png";
// import featureImg4 from "../../../assets/images/feature/feature_img4.png";

const Home3Feature = () => {
	return (
		<>
			<section className="sc-feature-section-area3 sc-pb-65 sc-pt-65 sc-md-pb-10">
				<div className="container">
					<div className="row align-items-center">
						<Fade bottom>
							<div className="col-lg-6">
								<div className="sc-feature-item sc-pr-110 sc-md-pr-0 sc-sm-pr-0">
									<div className="sc-heading-area sc-mb-30">
										<span className="sc-sub-title primary-color text-uppercase">Feature</span>
										<h2 className="heading-title sc-mb-20">Been Seen Outside The App.</h2>
										<div className="description sc-mb-40">
											BattleGrind can create a Slack channel for your team, and send updates from the game. This is great for the team to celebrate
											achievements, breaking company records, etc.
										</div>
										<div className="banner-btn-area">
											<a className="primary-btn" href="https://app.battlegrind.io/login">
												<span>Try It Now</span>
											</a>
										</div>
									</div>
								</div>
							</div>
						</Fade>
						<Fade bottom>
							<div className="col-lg-6">
								<div className="feature-image-area shake-y">
									<img className="feature-img" src={featureImg3BG} alt="Feature" />
									{/* <img className="feature-img_1 pulse" src={featureImg4} alt="Feature" /> */}
									<div className="feature-circle-shape"></div>
								</div>
							</div>
						</Fade>
					</div>
				</div>
			</section>
		</>
	);
};

export default Home3Feature;
