// import React, { useState } from "react";
import { Fade } from "@successtar/react-reveal";
// import ModalVideo from "react-modal-video";
// import { HashLink as Link } from "react-router-hash-link";
// import bannerBG from "../../../assets/images/banner/banner-bg.png";
// import bannerBGS from "../../../assets/images/banner/banner-bg-S.png";
import bannerBGbg from "../../../assets/images/banner/banner-bg-BG.png";
// import bannerBG2 from "../../../assets/images/banner/banner-bg2.png";
// import bannerBG2S from "../../../assets/images/banner/banner-bg2-S.png";
import bannerBG2bg from "../../../assets/images/banner/banner-bg2-BG.png";
// import bannerBG3 from "../../../assets/images/banner/banner-bg3.png";
// import bannerBG3S from "../../../assets/images/banner/banner-bg3-S.png";
import bannerBG3bg from "../../../assets/images/banner/banner-bg3-BG.png";

const Banner = () => {
	// const [isOpen, setOpen] = useState(false);
	return (
		<>
			<section className="sc-banner-section-area bottom-skew-bg sc-pt-200 sc-sm-pt-150 sc-pb-50 sc-sm-pb-70">
				<Fade top>
					<div className="banner-circle-shape"></div>
				</Fade>
				<div className="container-fluid p-0">
					<div className="row sc-banner-item d-flex align-items-center">
						<div className="col-md-6">
							<div className="banner-header-content">
								<Fade left>
									<h1 className="banner-title p-z-idex white-color sc-mb-25 sc-sm-mb-15">Sales as a Game</h1>
									<div className="banner-sub-title white-dark-color sc-mb-40 sc-sm-mb-25">
										BattleGrind is a sales gamification platform for B2B sales teams that want more fun, tools, and data to help them win.
									</div>
								</Fade>
								<div className="banner-btn-area d-flex align-items-center">
									{/* <Link className="primary-btn2" smooth to="/price#">
										<span>Take Poll</span>
									</Link> */}
									<a
										href="https://docs.google.com/forms/d/e/1FAIpQLSfvtJ7JPifbzUcbUlJutsfpcx1q-VeB5rH_LxYqiXgi2_w4Ug/viewform?usp=sf_link"
										className="primary-btn2"
									>
										Take Poll
									</a>
									{/* <ModalVideo channel="youtube" autoplay isOpen={isOpen} videoId="1pZ4X4PGEzo" onClose={() => setOpen(false)} /> */}

									<a href="https://www.youtube.com/watch?v=1pZ4X4PGEzo" className="popup-video" target="_blank" rel="noreferrer">
										<i className="ri-play-fill"></i>
										<span>Watch Latest Update</span>
									</a>
								</div>
							</div>
						</div>
						<div className="col-md-6">
							<div className="banner-image text-end shake-y">
								<img className="banner-img" src={bannerBGbg} alt="Banner" />
								<img className="banner-img_1 pulse" src={bannerBG2bg} alt="Banner" />
								<img className="banner-img_2 pulse" src={bannerBG3bg} alt="Banner" />
							</div>
						</div>
					</div>
				</div>
				<div className="border-shape"></div>
			</section>
		</>
	);
};

export default Banner;
