import React from "react";
import { Fade } from "@successtar/react-reveal";
// import { HashLink as Link } from "react-router-hash-link";
// import featureImg5 from "../../../assets/images/feature/feature_img5.png";
// import featureImg6 from "../../../assets/images/feature/feature_img6.png";
import aboutImageBG from "../../../assets/images/about/about-image-BG.png";

const Home3Feature2 = () => {
	return (
		<>
			<section className="sc-feature-section-area4 gray-bg sc-pb-55 sc-pt-55 sc-md-pb-70">
				<div className="container">
					<div className="row align-items-center">
						<Fade left>
							<div className="col-lg-6">
								<div className="sc-feature-image shake-y">
									<img className="feature-image" src={aboutImageBG} alt="aboutImage" />
									{/* <img className="feature-image_1 pulse" src={featureImg6} alt="About" /> */}
									<div className="feature-circle-shape"></div>
								</div>
							</div>
						</Fade>
						<Fade right>
							<div className="col-lg-6">
								<div className="sc-feature-item sc-pr-110 sc-md-pr-0 sc-sm-pr-0">
									<div className="sc-heading-area sc-mb-20">
										<span className="sc-sub-title primary-color text-uppercase">Feature</span>
										<h2 className="heading-title sc-mb-25">Compete with Real Time Leaderboards</h2>
										<div className="description sc-mb-25">
											Quit digging through various reports and dashboard in siloed systems. With BattleGrind, see how you stack up in real time.
										</div>
									</div>
									<div className="feature_check sc-mb-40">
										<ul>
											<li>
												<i className="ri-checkbox-circle-fill"></i>Standardized data makes comparison apples to apples
											</li>
											<li>
												<i className="ri-checkbox-circle-fill"></i>Show off your items and achievements to your peers
											</li>
											<li>
												<i className="ri-checkbox-circle-fill"></i>Watch live - no refreshes or data input required
											</li>
										</ul>
									</div>
									<div className="banner-btn-area">
										<a className="primary-btn" href="https://app.battlegrind.io/login">
											<span>Get Started</span>
										</a>
									</div>
								</div>
							</div>
						</Fade>
					</div>
				</div>
			</section>
		</>
	);
};

export default Home3Feature2;
