import React from "react";
import { Fade } from "@successtar/react-reveal";
// import { HashLink as Link } from "react-router-hash-link";
// import aboutImage from "../../assets/images/about/about-image.png";
// import aboutImage1 from "../../assets/images/about/about-img_1.png";
// import aboutImageS from "../../assets/images/about/about-image-S.png";
// import aboutImage1S from "../../assets/images/about/about-img_1-S.png";
import aboutImageBG from "../../assets/images/about/about-image-BG.png";
import aboutImage1BG from "../../assets/images/about/about-img_1-BG.png";

const AboutUs = () => {
	return (
		<>
			<section className="sc-about-area gray-bg sc-pt-140 sc-pb-180 sc-md-pt-80 sc-md-pb-70">
				<div className="container">
					<div className="row align-items-center">
						<Fade left>
							<div className="col-lg-6">
								<div className="image-area sc-md-mb-150">
									<div className="sc-about-image">
										<img className="about-image" src={aboutImageBG} alt="aboutImage" />
										<img className="about-image_1 pulse" src={aboutImage1BG} alt="About" />
										<div className="circle-shape pulse"></div>
									</div>
								</div>
							</div>
						</Fade>
						<Fade right>
							<div className="col-lg-6">
								<div className="sc-about-item sc-pl-100">
									<div className="sc-heading-area sc-mb-30 sc-sm-mb-15">
										<h2 className="heading-title">Turn The Grind Into a Game</h2>
									</div>
									<div className="description sc-mb-25 sc-sm-mb-15">
										Unleash the full potential of your B2B sales team with BattleGrind, the ultimate sales gamification platform. Our innovative
										solution transforms the traditional sales experience into a fun, engaging, and rewarding journey. Your sales reps will compete in
										dynamic challenges, earn valuable digital rewards, and showcase their skills to their peers. With BattleGrind, selling is no
										longer just a job, it's a game. Experience the thrill of competition, the satisfaction of achievement, and the boost in
										performance that comes with it. Join the Battle and maximize your sales results today!
									</div>
									<div className="btn-area sc-mt-40">
										{/* <Link className="primary-btn" to="/price#">
											<span>Sign up for Beta</span>
										</Link> */}
										<a href="https://www.battlegrind.io/register" rel="noreferrer" className="primary-btn">
											<span>Sign up for Beta</span>
										</a>
									</div>
								</div>
							</div>
						</Fade>
					</div>
				</div>
			</section>
		</>
	);
};

export default AboutUs;
