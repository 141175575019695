import React from "react";
import Header from "../../Shared/Header";
import Banner from "./Banner";
// import Service from "../../Shared/Service";
import AboutUs from "../../Shared/AboutUs";
import Feature from "../../Shared/Feature";
import Works from "../HomeTwo/Works";
// import Business from "../HomeTwo/Business";
import Home3Feature from "../HomeThree/Home3Feature";
import Home3Feature2 from "../HomeThree/Home3Feature2";
import Home3Services from "../HomeThree/Home3Services";
// import Counter from "./Counter";
// import Testimonial from "./Testimonial";
// import PricePlan from "../../Shared/PricePlan";
// import LatestBlog from "../../Shared/LatestBlog";
import Apps from "../../Shared/Apps";

const HomeOne = () => {
	return (
		<div>
			<Header parentMenu="home" headerClass="sc-header-section" />
			<Banner />
			<AboutUs />
			<Home3Services />
			<Feature />
			<Works />
			<Home3Feature2 />
			<Home3Feature />
			<Apps />
		</div>
	);
};

export default HomeOne;
